import { lazy } from "react";
import { Navigate } from "react-router";
import DepositWallet from "src/pages/user/financial/deposit";
import Ewallet from "src/pages/user/financial/ewallet";
import FundTransfer from "src/pages/user/financial/fundTransfer";
import Loadable from "../Loadable";

const Plan = Loadable(lazy(() => import("src/pages/user/plan/index")));

const plan = {
  path: "plan",
  element: <Plan />,
};

export default plan;
