import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EmptyTable from "src/components/emptyTable";
import PaginationButtons from "src/components/pagination";
import Scrollbar from "src/components/Scrollbar";
import useLocales from "src/hooks/useLocales";
import { BodyRow } from "src/pages/admin/reports/components";
import useFetchWitPagination from "../../hooks/useFetchWithPagination";
import { useForm } from "react-hook-form";
import DataFilter from "./dataFilter";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const firstDate = new Date(currentYear, currentMonth, 1).toLocaleDateString(
  "en-GB"
);
const lastDate = new Date(currentYear, currentMonth + 1, 0).toLocaleDateString(
  "en-GB"
);
export const defaultValues = {
  start_date: firstDate,
  end_date: lastDate,
  user_id: null,
};
const DataList = () => {
  const methods = useForm({ defaultValues });
  const filter = methods.watch();
  const { data, fetchData, rowStart, ...rest } = useFetchWitPagination(
    filter,
    "ewallet"
  );
  const { translate } = useLocales();
  const isEmpty = !Boolean(data?.length);
  const onFilter = methods.handleSubmit((inputData) => {
    fetchData(1, inputData);
  });
  return (
    <>
      <Card sx={{ mt: 2 }}>
        <DataFilter methods={methods} onFilter={onFilter} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720, mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{translate("userFinancial.eWallet.no")}</TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.fromUser")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.amountType")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.amount")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.date")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.paymentStatus")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {isEmpty ? (
                <BodyRow>
                  <TableCell colSpan={7} align="center">
                    <EmptyTable title="No Data Available" />
                  </TableCell>
                </BodyRow>
              ) : (
                <TableBody>
                  {data.map((item, i) => (
                    <TableRow>
                      <TableCell>{i + rowStart}</TableCell>
                      <TableCell>{item.from_user.username}</TableCell>
                      <TableCell>{item.payment_type}</TableCell>
                      <TableCell>{item.total_amount}</TableCell>
                      <TableCell>
                        {new Date(item.created_at).toLocaleDateString("en-GB")}
                      </TableCell>
                      <TableCell>{item.payment_status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
      <PaginationButtons {...rest} />
    </>
  );
};

export default DataList;
