import { LoadingButton } from "@mui/lab";
import { Box, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "src/components/hook-form";
import Password from "src/components/Password";
import { PATH_AUTH } from "src/routes/paths";
import AuthHelper from "./components/authHelper";
import ErrorBanner from "./components/errorBanner";
import useLogin from "./hooks/useLogin";

const LoginForm = () => {
  const { methods, onSubmit } = useLogin();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack className="loginFormSection" spacing={2} sx={{ mb: 1 }}>
   
        <AuthHelper />
        <ErrorBanner />
      </Stack>

      <Box>

      <Typography variant="h5" sx={{mb:1}}>
          Hi, Welcome Back
        </Typography>
      <Typography  sx={{ fontSize:"14px", mb: 5 }}>
        Please sign-in to your account and start the adventure
        </Typography>


        <Stack spacing={2}>
          <RHFTextField name="email" label="Email address" />
          <Password name="password" label="Password" />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <RHFCheckbox name="remember" label="Remember me" />
          <Link
            component={RouterLink}
            variant="subtitle2"
            to={PATH_AUTH.resetPassword}
          >
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          name="login"
        >
          Login
        </LoadingButton>
      </Box>
    </FormProvider>
  );
};

export default LoginForm;
